span 
{
  width: 110px;
  height: 61.11px;
  display: inline-block;
  position: relative;
}
.video-thumbnail {
  width: 110px;
  height: 61.11px;
  transition: all 0.3s ease;
  position: absolute;
  top:0%;
  left: 0%;
  transform: translate(-50%,-50%);
}

.video-thumbnail:hover {
  width: 130px;
  height: 72.222px;
  z-index: 11;
  left:-10px;
}

/* Add smooth transition for the magnetic effect */
.video-thumbnail {
  transition: all 0.3s ease, transform 0.2s ease-out;
}

@media (max-width:767px) {
  .video-thumbnail:hover {
    width: 110px;
    height: 61.11px;
    left: 0;
  }
}