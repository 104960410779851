.coming-soon {
    width: 100vw;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    padding: 64px 74px;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;

    background: linear-gradient(180deg, #FCF7F7 0%, #F4DFDF 100%);
}
#HoverImage {
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 2;
    transition: transform 0.1s ease-out;
  }
.video-thumbnail {
    width: 110px;
    height: 61.11px;
}
.coming-soon::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('./assets/Overlay Noise.svg');
    background-size: cover;
    background-position: center;
    opacity: 1;
    z-index: 1; 
}

.coming-soon .container-outer {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.coming-soon .rotating-bg {
    opacity: 0.2;
    position: fixed;
    top: auto;
    left: 0;
    width: 100%;
    height: 500px;
    background-image: url('./assets/chakra.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    animation: rotateBackground 500s linear infinite;

}

.coming-soon .container {
    position: relative;
    z-index: 2; /* Keeps content above rotating background */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    /* min-height: 500px; */
}

/* Keyframes for rotation */
@keyframes rotateBackground {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.coming-soon h2 {
    margin: 0;
    color: #444;
    text-align: center;
    font-family: "Moret", sans-serif;
    font-size: 4.3rem;
    font-style: normal;
    font-weight: 300;
    line-height: 100%;
    letter-spacing: -1.4px;
    width: 100%;
    max-width: 802px;
}

.coming-soon p {
    margin: 0;
    color: #444;
    text-align: center;
    font-family: "Bricolage Grotesque", sans-serif;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 85%;
    /* 27.2px */
    letter-spacing: -0.64px;
    margin-bottom: 8px;
}

.coming-soon .buttons {
    z-index: 200;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.coming-soon .buttons a {
    text-decoration: none;
    cursor: pointer;
}

/* .coming-soon .buttons button {
    display: flex;
    padding: 16px 18px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    color: #FFF;
    text-align: center;
    font-family: "Bricolage Grotesque", sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 90%;
    letter-spacing: -0.32px;
    text-decoration: none;
    border-radius: 55px;
    border: 1px solid #FFF;
    background: #D33163;
    letter-spacing: -0.32px;
    box-shadow: 0px 4px 8px 0px #A8244D inset, 0px 6px 12px 0px rgba(211, 49, 99, 0.25), 0px 0px 0px 2px rgba(211, 49, 99, 0.05);
    cursor: pointer;
} */

/* .coming-soon .buttons a:first-child button {
    border-radius: 55px;
    border: 1px solid #FFF;
    padding: 16px 29.5px;
    background: #FFF;
    color: #D33163;
    box-shadow: 0px 4px 8px 0px #F6E6E6 inset, 0px 6px 12px 0px rgba(211, 49, 99, 0.12), 0px 0px 0px 2px rgba(211, 49, 99, 0.05);
} */

.coming-soon .footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    z-index: 10;
}

.coming-soon .footer a {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #444444;
    text-align: center;
    font-family: "Inter", sans-serif;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 400;
    line-height: 90%;
    /* 21.6px */
    letter-spacing: -1.68px;
    cursor: pointer;
}

.coming-soon .footer a:hover{
    color: #444444;
}

.coming-soon .footer a.clicked {
    color: #292929;
}

@media (max-width:767px) {
    .video-thumbnail {
        width: 80px;
        height: 44.44px;
    }
    .coming-soon .buttons button {
        letter-spacing: -0.32px;
        padding: 16px 18px;
    }
    .coming-soon {
        padding: 24px;
        height: 100%;
        padding-bottom: 48px;
    }

    .coming-soon h2 {
        font-size: 3rem;
    }

    .coming-soon p {
        font-size: 1.5rem;
    }

    .coming-soon .footer a {
        font-size: 1.25rem;
    }

    .rotating-bg {
        display: none;
    }
}

@media (max-width:450px) {
    .coming-soon h2 {
        max-width: 320px;
    }
}