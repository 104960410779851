.custom-button-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 32px;
    border-radius: 55px;
    border: 1px solid #fff;
    background: #d33163;
    box-shadow: 0px 4px 8px 0px #a8244d inset,
      0px 6px 12px 0px rgba(211, 49, 99, 0.25),
      0px 0px 0px 2px rgba(211, 49, 99, 0.05);
    cursor: pointer;
    color: #fff;
    text-align: center;
    font-family: "Bricolage Grotesque";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 90%;
    letter-spacing: -0.32px;
    transition: all 0.1s ease;
  }
  
  .custom-button-1:hover {
    background: #db5780;
    box-shadow: 0px 4px 8px 0px #d33163 inset,
      0px 6px 12px 0px rgba(211, 49, 99, 0.5),
      0px 0px 0px 2px rgba(211, 49, 99, 0.05);
    font-weight: 600;
  }
  
  .custom-button-1:active {
    transform: scale(0.98);
    box-shadow: 0px 2px 4px 0px #a8244d inset,
      0px 3px 6px 0px rgba(211, 49, 99, 0.25),
      0px 0px 0px 2px rgba(211, 49, 99, 0.05);
  }
  
  .custom-button-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 32px;
    border-radius: 55px;
    border: 1px solid #fff;
    background: #fff;
    box-shadow: 0px 4px 8px 0px #f6e6e6 inset,
      0px 6px 12px 0px rgba(211, 49, 99, 0.12),
      0px 0px 0px 2px rgba(211, 49, 99, 0.05);
    color: #d33163;
    text-align: center;
    font-family: "Bricolage Grotesque";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 90%;
    letter-spacing: -0.32px;
    transition: all 0.1s ease;
    cursor: pointer;
  }
  
  .custom-button-2:hover {
    background: #fff0f4;
    box-shadow: 0px 4px 8px 0px #f6e6e6 inset,
      0px 6px 12px 0px rgba(211, 49, 99, 0.4),
      0px 0px 0px 2px rgba(211, 49, 99, 0.05);
  }
  
  .custom-button-2:active {
    transform: scale(0.98);
    box-shadow: 0px 2px 4px 0px #f6e6e6 inset,
      0px 3px 6px 0px rgba(211, 49, 99, 0.12),
      0px 0px 0px 2px rgba(211, 49, 99, 0.05);
  }
  
  .custom-button-1:disabled,
  .custom-button-2:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    transform: none;
  }