@import url('https://fonts.cdnfonts.com/css/moret');
/*  font-family: "Moret", sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200..800&display=swap');
/* font-family: "Bricolage Grotesque", sans-serif; */
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
/* font-family: "Inter", sans-serif; */
.App {
  text-align: center;
  padding: 0;
  margin: 0;
}
